<template>
  <div>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Excluir Registro </v-card-title>
        <v-card-text
          >Confirma a Exclusão Permanentemente do Registro? Após Excluir, NÃO
          Será Possível REVERTER</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            CANCELAR
          </v-btn>
          <v-btn color="red" text @click="deleteItem"> EXCLUIR </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <v-card>
        <v-toolbar elevation="0">
          <v-row class="d-flex justify-space-around">
            <v-col cols="4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
                height="40"
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="2">
              <v-btn color="primary" @click="openDialog = true">Novo</v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="movements"
          :search="search"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon small class="mr-2" @click="deleteOpen(item.id)"
              >mdi-eraser</v-icon
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-row justify="center">
      <v-dialog
        v-model="openDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar>
            <v-btn icon @click="closeDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Movimento de Estoque</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-container fluid>
              <v-card outlined>
                <v-card-subtitle bold>Dados Gerais</v-card-subtitle>
                <v-divider />
                <v-container fluid>
                  <v-row>
                    <v-col cols="2">
                      <v-select
                        outlined
                        :items="['ENTRADA', 'SAIDA']"
                        v-model="type"
                        label="Tipo"
                      ></v-select>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        outlined
                        type="date"
                        label="Data Pedido"
                        v-model="requestDate"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-autocomplete
                        outlined
                        :items="locations"
                        v-model="stockLocationId"
                        label="Local Estoque"
                        item-text="name"
                        item-value="id"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                      <v-autocomplete
                        outlined
                        :items="providers"
                        v-model="providerCompanyId"
                        label="Fornecedor"
                        item-text="company.socialName"
                        item-value="id"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="2">
                      <v-text-field
                        label="Número Nota"
                        v-model="document"
                        required
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-autocomplete
                        outlined
                        :items="documents"
                        v-model="documentTypeId"
                        label="Tipo de Nota"
                        item-text="name"
                        item-value="id"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        label="Chave"
                        v-model="documentKey"
                        required
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-autocomplete
                        outlined
                        :items="shippings"
                        v-model="shippingCompanyId"
                        label="Transportadora"
                        item-text="company.socialName"
                        item-value="id"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <v-row justify="center">
                <v-container fluid>
                  <v-expansion-panels accordion>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        >Produtos</v-expansion-panel-header
                      >
                      <v-divider />
                      <v-expansion-panel-content>
                        <v-form ref="form">
                          <v-row>
                            <v-col cols="12" sm="3">
                              <v-select
                                outlined
                                v-model="productId"
                                :items="productList"
                                item-text="name"
                                item-value="id"
                                label="Produto"
                                required
                              ></v-select>
                            </v-col>
                            <v-col cols="12" sm="1">
                              <v-text-field
                                outlined
                                v-model="pQtd"
                                label="QTD"
                              />
                            </v-col>

                            <v-col cols="12" sm="2">
                              <v-text-field
                                outlined
                                v-money="money"
                                v-model.lazy="unitCostPrice"
                                label="Custo Unitário"
                              />
                            </v-col>

                            <v-col cols="12" sm="2">
                              <v-text-field
                                outlined
                                v-model="margin"
                                label="Margem %"
                                @change="changePrice()"
                              />
                            </v-col>

                            <v-col cols="12" sm="2">
                              <v-text-field
                                outlined
                                prefix="R$ "
                                v-model="unitSalePrice"
                                label="Venda Unitário"
                              />
                            </v-col>

                            <v-col cols="12" sm="2">
                              <v-btn fab @click="addProduct">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-form>
                        <v-data-table
                          :headers="stockHeaders"
                          :items="products"
                          hide-default-footer
                          class="elevation-1"
                        >
                          <template v-slot:[`item.unitCostPrice`]="{ item }">
                            <span
                              >R$ {{ formatPrice(item.unitCostPrice) }}</span
                            >
                          </template>
                          <template v-slot:[`item.amountCostPrice`]="{ item }">
                            <span
                              >R$ {{ formatPrice(item.amountCostPrice) }}</span
                            >
                          </template>
                          <template v-slot:[`item.unitSalePrice`]="{ item }">
                            <span
                              >R$ {{ formatPrice(item.unitSalePrice) }}</span
                            >
                          </template>
                          <template v-slot:[`item.amountSalePrice`]="{ item }">
                            <span
                              >R$ {{ formatPrice(item.amountSalePrice) }}</span
                            >
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-container>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!id || !products.length || consolidated"
              color="blue darken-1"
              text
              @click="consolidateMovementStock()"
            >
              Consolidar
            </v-btn>
            <v-btn color="blue darken-1" text @click="closeDialog()">
              Fechar
            </v-btn>
            <v-btn
              :loading="loadingAction"
              color="success"
              @click="registerOrUpdate()"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { VMoney } from "v-money";
export default Vue.extend({
  name: "StockMovement",
  directives: { money: VMoney },
  data: () => ({
    deleteDialog: false,
    consolidated: false,
    movements: [],
    locations: [],
    providers: [],
    shippings: [],
    documents: [],
    products: [],
    productList: [],
    search: "",
    loading: false,
    loadingAction: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false /* doesn't work with directive */,
    },
    id: "",
    requestDate: "",
    document: "",
    type: "",
    providerCompanyId: "",
    shippingCompanyId: "",
    stockLocationId: "",
    documentTypeId: "",
    documentKey: "",
    //productStock
    productId: "",
    pQtd: "",
    unitSalePrice: "",
    unitCostPrice: "",
    margin: "",
    openDialog: false,
    headers: [
      {
        text: "Data Entrada",
        align: "start",
        value: "entryDate",
        filtering: true,
      },
      {
        text: "Local Estoque",
        align: "start",
        value: "stockLocation.identifier",
        filtering: true,
      },
      {
        text: "Fornecedor",
        align: "start",
        value: "providerCompany.company.socialName",
        filtering: true,
      },
      {
        text: "Nota",
        align: "start",
        value: "document",
        filtering: true,
      },
      {
        text: "Tipo",
        align: "start",
        value: "type",
        filtering: true,
      },
      {
        text: "Consolidado Em",
        align: "start",
        value: "consolidatedAt",
        filtering: true,
      },
      { text: "Ações", value: "actions" },
    ],
    stockHeaders: [
      {
        text: "QTD",
        align: "start",
        value: "quantity",
        filtering: true,
      },
      {
        text: "Produto",
        align: "start",
        value: "product.name",
        filtering: true,
      },
      {
        text: "Custo Unitário",
        align: "start",
        value: "unitCostPrice",
        filtering: true,
      },
      {
        text: "Custo Total",
        align: "start",
        value: "amountCostPrice",
        filtering: true,
      },
      {
        text: "Venda Unitário",
        align: "start",
        value: "unitSalePrice",
        filtering: true,
      },
      {
        text: "Venda Total",
        align: "start",
        value: "amountSalePrice",
        filtering: true,
      },

      { text: "Ações", value: "actions" },
    ],
  }),
  methods: {
    consolidateMovementStock() {
      if (this.id) {
        http.post(`stock-movements/consolidation/${this.id}`).then(
          (data) => {
            this.snackbar.color = "green";
            this.snackbar.text = data.data.message;
            this.snackbar.opened = true;
            this.consolidated = true;
          },
          ({ message }) => {
            console.log(message);
            this.snackbar.color = "red";
            this.snackbar.text = message;
            this.snackbar.opened = true;
          }
        );
      } else {
        this.snackbar.text = "Salve o Lançamento antes de Consolidar";
        this.snackbar.color = "orange";
        this.snackbar.opened = true;
      }
    },
    changePrice() {
      this.unitCostPrice = this.unitCostPrice.replace("R$ ", "");
      this.unitCostPrice = this.unitCostPrice.replace(".", "");
      this.unitCostPrice = this.unitCostPrice.replace(",", ".");

      if (this.unitCostPrice) {
        const margin = (this.unitCostPrice * this.margin) / 100;
        this.unitSalePrice = this.formatPrice(
          (margin * 1 + this.unitCostPrice * 1).toFixed(2)
        );
        console.log(this.unitSalePrice);
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    deleteOpen(itemId) {
      this.id = itemId;
      this.deleteDialog = true;
    },
    async addProduct() {
      let unitSalePrice = this.unitSalePrice.toString().replace("R$ ", "");
      unitSalePrice = unitSalePrice.replace(".", "");
      unitSalePrice = unitSalePrice.replace(",", ".") * 1;

      let unitCostPrice = this.unitCostPrice.toString().replace("R$ ", "");
      unitCostPrice = unitCostPrice.replace(".", "");
      unitCostPrice = unitCostPrice.replace(",", ".") * 1;

      const tr = await http.get(`products/${this.productId}`).then((data) => {
        const pay = {
          product: data.data,
          quantity: this.pQtd,
          productId: data.data.id,
          stockLocationId: this.stockLocationId,
          unitCostPrice,
          unitSalePrice,
          amountCostPrice: unitCostPrice * this.pQtd,
          amountSalePrice: unitSalePrice * this.pQtd,
        };
        this.products.push(pay);
      });
      this.registerOrUpdate();
    },
    deleteItem() {
      http
        .delete(`stock-movements/${this.id}`)
        .then(() => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Excluido com Sucesso!";
          this.snackbar.opened = true;
          this.deleteDialog = false;
          this.getItems();
        })
        .catch((error) => {
          console.log(error.response);
          this.snackbar.color = "red";
          this.snackbar.text = error?.response.data.message;
          this.snackbar.opened = true;
        });
    },
    getItems() {
      this.loading = true;
      http.get("stock-movements").then((data) => {
        this.movements = data.data;
        this.loading = false;
      });
    },
    getProducts() {
      http.get("products").then((data) => {
        this.productList = data.data;
      });
    },
    getShippings() {
      this.loading = true;
      http.get("company/shippings").then((data) => {
        this.shippings = data.data;
      });
    },
    getLocations() {
      this.loading = true;
      http.get("company/stock-locations").then((data) => {
        this.locations = data.data;
      });
    },
    getDocuments() {
      this.loading = true;
      http.get("document-types").then((data) => {
        this.documents = data.data;
      });
    },
    getProviders() {
      this.loading = true;
      http.get("company/providers").then((data) => {
        this.providers = data.data;
      });
    },
    registerOrUpdate() {
      this.loadingAction = true;
      const payload = {
        entryDate: new Date(),
        document: this.document,
        documentKey: this.documentKey,
        requestDate: this.requestDate,
        shippingCompanyId: this.shippingCompanyId,
        stockLocationId: this.stockLocationId,
        type: this.type,
        documentTypeId: this.documentTypeId,
        providerCompanyId: this.providerCompanyId,
        items: this.products,
      };
      if (this.id) {
        http.put(`stock-movements/${this.id}`, payload).then(
          (data) => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Atualizado com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.cleanAddItem(data.data.id);
            this.products = data.data.items;
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Atualizar. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      } else {
        http.post("stock-movements", payload).then(
          (data) => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Inserido com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.cleanAddItem(data.data.id);
            this.products = data.data.items;
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Registro. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      }
    },
    closeDialog() {
      this.openDialog = false;
      this.id = "";
      this.entryDate = "";
      this.document = "";
      this.documentKey = "";
      this.requestDate = "";
      this.shippingCompanyId = "";
      this.stockLocationId = "";
      this.type = "";
      this.documentTypeId = "";
      this.providerCompanyId = "";
      this.consolidated = false;
      this.getItems();
    },
    cleanAddItem(id) {
      this.id = id;
      this.unitCostPrice = 0.0;
      this.unitSalePrice = "";
      this.amountCostPrice = "";
      this.amountSalePrice = "";
      this.$refs.form.reset();
    },
    editItem(item) {
      this.id = item.id;
      this.entryDate = item.entryDate;
      this.document = item.document;
      this.documentKey = item.documentKey;
      this.requestDate = item.requestDate;
      this.shippingCompanyId = item.shippingCompanyId;
      this.stockLocationId = item.stockLocationId;
      this.type = item.type;
      this.documentTypeId = item.documentTypeId;
      this.providerCompanyId = item.providerCompanyId;
      this.products = item.items;
      this.consolidated = item.consolidated;
      this.openDialog = true;
    },
  },

  mounted() {
    this.getItems();
    this.getShippings();
    this.getLocations();
    this.getDocuments();
    this.getProviders();
    this.getProducts();
  },
});
</script>